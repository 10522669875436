// Semplice metodo per renderizzare una stringa vuota o un placeholder se il valore di riferimento è inesistente

export const renderValue = (value, key, placeholder) => (value && value[key]) ? value[key] : placeholder

export const refactorWord = (word) => {

    if(!word){
        return '';
    }

    let correctedWord =  word.replace('_', ' ');
    const wordSplitted = [];

    correctedWord.split(" ").forEach(word => {
        wordSplitted.push(word.charAt(0).toUpperCase()+word.substring(1).toLowerCase())
    });



    return wordSplitted.join(' ');
}