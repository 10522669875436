import React from 'react';
import './HeaderCommessa.scss';
import { renderValue } from '../../utils/renderValue';
import { convertDateIt, mese } from '../../utils/dates';
import { convertEuro } from '../../utils/currencyConversion';
import leftArrow from '../../../assets/img/left-arrow.png';
import { useHistory } from 'react-router-dom';

const HeaderCommessa = ({ commessaSelezionata }) => {
	const date = new Date();
	const history = useHistory();

	const actualCostsCommessa = commessaSelezionata?.actualCosts?.reduce((acc, { costo }) => acc += costo, 0)

	return (
		<div className="row header-commessa-ref">
			<div className="title d-flex w-100 align-items-center mr-5 pt-5 pl-4">
				<img
					src={leftArrow}
					onClick={() => history.goBack()}
					alt="Torna Indietro"
					className="mr-4 cursor-pointer"
				/>
				<h2 className="text-white m-0">Path to the future</h2>
			</div>
			<div className="t-header">
				<div>
					<span className="desc">Progetto: </span>
					<strong className="name">
						{commessaSelezionata && renderValue(commessaSelezionata.commessaDto, 'nomeProgetto')}
					</strong>
				</div>
				<div>
					<span className="desc">Start/End: </span>
					<strong className="name">
						{commessaSelezionata && commessaSelezionata.dataInizioWbs ? (
							convertDateIt(commessaSelezionata.dataInizioWbs)
						) : (
							''
						)}
						&nbsp;-&nbsp;
						{commessaSelezionata && commessaSelezionata.dataFineWbs ? (
							convertDateIt(commessaSelezionata.dataFineWbs)
						) : (
							''
						)}
					</strong>
				</div>
			</div>

			<div className="t-header">
				<div>
					<span className="desc">Cliente: </span>
					<strong className="name">
						{commessaSelezionata && commessaSelezionata.commessaDto.codiceCliente}
					</strong>
				</div>
				<div>
					<span className="desc">Open Month: </span>
					<strong className="name">{`${mese[date.getMonth()]} ${date.getFullYear()}`}</strong>
				</div>
			</div>

			<div className="t-header">
				<div>
					<span className="desc">Actual Cost: </span>
					<strong className="name">
						{commessaSelezionata && convertEuro(actualCostsCommessa || 0)}
					</strong>
				</div>
				<div>
					<span className="desc">Contracted Amount: </span>
					<strong className="name">
						{commessaSelezionata &&
							convertEuro(commessaSelezionata.commessaDto.contractedAmountOfferta || 0)}
					</strong>
				</div>
			</div>

			<div className="t-header">
				<div>
					<span className="desc">Status: </span>
					<strong className="name">{renderValue(commessaSelezionata, 'statoWbs')}</strong>
				</div>
				<div>
					<span className="desc">Budgeted Margin:</span>
					<strong className="name">{renderValue(commessaSelezionata, 'budgetedMargin') || '-'}</strong>
				</div>
			</div>
		</div>
	);
};

export default HeaderCommessa;
