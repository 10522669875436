import {
    SET_BUSINESS_UNITS,
    TOGGLE_LOADING,
    TOGGLE_MENU,
    TOGGLE_TOAST
} from '../actions/general';

const initialState = {
    loading: false,
    menu: false,
    toast: null,
    callSelected: null,
    businessUnits: []
};

const general = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_LOADING: {
            return {
                ...state,
                loading: action.force ? action.force.force : !state.loading
            }
        }
        case TOGGLE_MENU: {
            return {
                ...state,
                menu: !state.menu
            }
        }
        case TOGGLE_TOAST: {
            return {
                ...state,
                toast: state.toast ? null : action.toast
            }
        }
        case SET_BUSINESS_UNITS: {
            return {
                ...state,
                businessUnits: action.businessUnits
            }
        }
        default:
            return state;
    }
};

export default general;
