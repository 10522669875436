import React from 'react';
import spinner from '../../assets/img/Spinner-1s-200px.svg';

function LoadingSpinner() {
	return (
		<div className="loading-container">
			<img src={spinner} alt="Loading spinner" />
			<p className="text-center text-dark w-100">Attendere...</p>
		</div>
	);
}

export default LoadingSpinner;
