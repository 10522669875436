import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import './MenuProjectManagement.scss';

const MenuProjectManagement = () => {
	return (
		<div id="menu-container" className="mb-5 mt-3">
			{/* MAIN */}
			<Dropdown>
				<Dropdown.Toggle title="MAIN" />
				<Dropdown.Menu>
					<MenuItem>
						<Link to="/start-commessa">Start</Link>
					</MenuItem>
					<MenuItem>
						<Link to="/general-data-commessa">General Data</Link>
					</MenuItem>
					<MenuItem>
						<Link to="/project-data">Project Data</Link>
					</MenuItem>
				</Dropdown.Menu>
			</Dropdown>

			{/* PLANNING */}
			<Dropdown>
				<Dropdown.Toggle title="PLANNING" />
				<Dropdown.Menu>
					<MenuItem>
						<Link to="/define-wbs">Define WBS</Link>
					</MenuItem>
					<MenuItem>
						<Link to="/cost-budget">Cost Budget</Link>
					</MenuItem>
					<MenuItem>
						<Link to="/milestone-plan">Milestone Plan</Link>
					</MenuItem>
					<MenuItem>Billing Plan</MenuItem>
					<MenuItem>Risk Plan</MenuItem>
					<MenuItem>Operating Portfoglio / Rolling Forecast</MenuItem>
				</Dropdown.Menu>
			</Dropdown>

			{/* EXECUTION */}
			<Dropdown>
				<Dropdown.Toggle title="EXECUTION" />
				<Dropdown.Menu>
					<MenuItem>WBS Management</MenuItem>
					<MenuItem>
						Resource Management
						<MenuItem>Resource Plan</MenuItem>
						<MenuItem>Pyramids and histogram</MenuItem>
						<MenuItem>
							<Link to="/hours-approval">Hours Approval</Link>
						</MenuItem>
						<MenuItem>External Expenses Approval</MenuItem>
						<MenuItem>Project Evaluation</MenuItem>
					</MenuItem>
					<MenuItem>
						Purchase
						<MenuItem>Purchase Management</MenuItem>
					</MenuItem>
					<MenuItem>
						Cost Entry Control
						<MenuItem>Cost Transfer</MenuItem>
						<MenuItem>Transfer of Hours</MenuItem>
						<MenuItem>Provisions</MenuItem>
						<MenuItem>Committed Costs</MenuItem>
					</MenuItem>
				</Dropdown.Menu>
			</Dropdown>

			{/* TRACKING */}
			<Dropdown>
				<Dropdown.Toggle title="TRACKING" />
				<Dropdown.Menu>
					<MenuItem>
						<Link to="/current-situation">Current Situation</Link>
					</MenuItem>
					<MenuItem>Progress Measurement</MenuItem>
					<MenuItem>Billing</MenuItem>
					<MenuItem>Milestones Management</MenuItem>
					<MenuItem>Risk Management</MenuItem>
					<MenuItem>Problem Management</MenuItem>
					<MenuItem>
						Change Management
						<MenuItem>Scope</MenuItem>
					</MenuItem>
					<MenuItem>
						<Link to="/cost-analysis">Analysis of costs</Link>
					</MenuItem>
					<MenuItem>Results</MenuItem>
					<MenuItem>Project Reports</MenuItem>
				</Dropdown.Menu>
			</Dropdown>

			{/* CONTROL */}
			<Dropdown>
				<Dropdown.Toggle title="CONTROL" />
				<Dropdown.Menu>
					<MenuItem>Analysis of deviations</MenuItem>
					<MenuItem>
						Decision Making
						<MenuItem>Master Plan State Change</MenuItem>
					</MenuItem>
					<MenuItem>Alerts</MenuItem>
					<MenuItem>Cash Flow</MenuItem>
					<MenuItem>General Report</MenuItem>
				</Dropdown.Menu>
			</Dropdown>

			{/* CLOSING */}
			<Dropdown>
				<Dropdown.Toggle title="CLOSING" />
				<Dropdown.Menu>
					<MenuItem>
						<Link to="/project-evaluation">Project Evaluation</Link>
					</MenuItem>
					<MenuItem>
						<Link to="/closure-situation">Closure Situation</Link>
					</MenuItem>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

export default MenuProjectManagement;
