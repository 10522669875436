import React, {Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {routes} from './routes';
import LayoutCommessa from '../components/LayoutCommessa/LayoutCommessa';

// Metodo per includere componenti in uno specifico layout
export const WrappedRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <LayoutCommessa>
                <Component {...matchProps} />
            </LayoutCommessa>
        )}/>
    )
};

// Metodo per generare dei componenti Route a partire dell'array. Se è di tipo wrapped si usa l'apposito componente per cambiare layout
const getRouteComponent = routes => routes.map((r, i) => (
    !r.wrapped
        ? <Route path={r.pathname} exact key={i} component={r.component}/>
        : <WrappedRoute path={r.pathname} exact key={i} component={r.component}/>
));

// Metodo per definire l'array di path in base allo status di autenticazione ed il ruolo dell'utente
export const getRouteItems = user =>
    user
        ? routes.filter((r, i) => r.isPrivate && r.users.some(authority => user.authoritiesUtente.includes(authority)))
        : routes.filter((r, i) => !r.isPrivate)

// Generazione dello Switch con le routes ed il redirect in base allo stato di autenticazione dell'utente e alla presenza di path nel local storage
export const createRoutes = (user) => {
    const path = () => {
            if (user.authoritiesUtente.includes('DIREZIONE_TECNICA') || user.authoritiesUtente.includes('PROJECT_MANAGEMENT')) {
                return '/commesse';
            }
            if (user.authoritiesUtente.includes('CONTROLLO_GESTIONE')) {
                return '/data-summary';

            }
            if (user.authoritiesUtente.includes('HUMAN_RESOURCES')) {
                return '/anagrafica-risorse'
            }

            return '/timesheet';
    }

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                {getRouteComponent(getRouteItems(user))}
                <Redirect to={user // Se user autenticato prendo il path dal local storage (se presente) altrimenti vado a home
                    ? localStorage.getItem('path') || path()
                    : '/login'}/>
            </Switch>
        </Suspense>
    )
};
