import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import HeaderCommessa from './components/HeaderCommessa';
import MenuProjectManagement from '../Drawer/MenuProjectManagement';
import axiosInstance from '../../api/interceptor';
import {toggleToast, toggleSpinner} from '../../store/actionCreators/general';
import {checkDC, checkDT, checkHR, checkPM} from '../utils/checkAuthority';

const LayoutCommessa = ({commessaSelezionata, toggleToast, toggleSpinner, children}) => {
    const user = JSON.parse(localStorage.getItem('user'));

    // Variabili per verificare il tipo di utente collegato
    const isDT = checkDT(user);
    const isPM = checkPM(user);
    const isHR = checkHR(user);

    // Se non ci sono commesse selezionate redirect alla tabella delle commesse
    const history = useHistory();
    useEffect(() => {
        if (!commessaSelezionata || commessaSelezionata.length === 0) history.push('/commesse');
    });

    // States corrispondenti a elenchi PM, Supervisors e PM Vertical
    const [listPmVertical, setListPmVertical] = useState('');
    const [listPm, setListPm] = useState('');
    const [listSupervisor, setListSupervisor] = useState('');

    // FETCH ELENCO PM, SUPERVISORS E PM VERTICAL DA PASSARE COME PROPS A TUTTI COMPONENTI DELLA SEZIONE PROJECT MANAGEMENT
    const baseString = checkDC(user) ? '/direzione-commerciale' : checkPM(user) ? '/project-management' : '/direzione-tecnica';

    async function fetchSupervisors() {
        await axiosInstance
            .get(`${baseString}/utente/direzione-tecnica`)
            .then((res) => setListSupervisor(res.data))
            .catch((e) => toggleToast('e', e.messaggio));
    }

    async function fetchPM() {
        await axiosInstance
            .get(`${baseString}/utente/project-management`)
            .then((res) => setListPm(res.data))
            .catch((e) => toggleToast('e', e.messaggio));
    }

    async function fetchPmVertical() {
        await axiosInstance
            .get(`${baseString}/utente/direzione-commerciale`)
            .then((res) => setListPmVertical(res.data))
            .catch((e) => toggleToast('e', e.messaggio));
    }

    const getListAuthorities = async () => {
        toggleSpinner();
        await Promise.all([
            fetchSupervisors(), fetchPM(), fetchPmVertical()
        ]).finally(() => toggleSpinner());
    };

    useEffect(() => {
        if(!isHR){
        getListAuthorities();
        }
        //eslint-disable-next-line
    }, []);

    // ------- FINE SEZONE FETCH ELENCO PM, SUPERVISORS E PM VERTICAL -------

    // Metodo per passare props a tutti i props.children, nello specifico il reducer commessaSelezionata e gli elenchi delle authorities
    const childrenWithProps = React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
            commessaSelezionata,//In realtà è un wbsID
            isDT,
            isPM,
            listPmVertical,
            listPm,
            listSupervisor,
            
        })
    );

    return (

        <div className="col-12">
            <HeaderCommessa commessaSelezionata={commessaSelezionata}/>
            <div className="row">
                <div className="col-md-12 mt-3">
                    <MenuProjectManagement/>
                    <div id="layout-commessa-content">{childrenWithProps}</div>
                </div>
            </div>
        </div>

    );
};

const mapStateToProps = (state) => {
    return {
        commessaSelezionata: state.commessaSelezionata
    };
};

export default connect(mapStateToProps, {
    toggleToast,
    toggleSpinner
})(LayoutCommessa);
