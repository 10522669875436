/* TIPOLOGIE E LIVELLO DI UTENTI
[0] Admin = SUPER_USER
[1] Direzione = DIREZIONE
[1] Direzione tecnica = DIREZIONE_TECNICA
[1] Direzione commerciale = DIREZIONE_COMMERCIALE
[2] Controllo di gestione = CONTROLLO_GESTIONE
[2] Amministrazione = AMMINISTRAZIONE
[2] HR = HUMAN_RESOURCES
[3] PM = PROJECT_MANAGEMENT
[4] Risorse = RISORSA
*/

import React from 'react';


const Login = React.lazy(() => import ('../pages/Login/Login'));
const ChangePassword = React.lazy(() => import ("../pages/User/ChangePassword"));
const Commesse = React.lazy(() => import ('../pages/Commesse/Commesse'));
const Timesheet = React.lazy(() => import ('../pages/Timesheet/Timesheet'));
const AnagraficaRisorse = React.lazy(() => import ('../pages/AnagraficaRisorse/AnagraficaRisorse'));
const Clienti = React.lazy(() => import ('../pages/Clienti/Clienti'));
const Offerta = React.lazy(() => import ('../pages/Offerta/Offerta'));
const DataExport = React.lazy(() => import("../pages/DataExport/DataExport"));
const LayoutCommessa = React.lazy(() => import ('../components/LayoutCommessa/LayoutCommessa'));
const Start = React.lazy(() => import ('../pages/GestioneCommesse/Main/Start'));
const GeneralData = React.lazy(() => import ('../pages/GestioneCommesse/Main/GeneralData'));
const ProjectData = React.lazy(() => import ('../pages/GestioneCommesse/Main/ProjectData'));
const DefineWBS = React.lazy(() => import ('../pages/GestioneCommesse/Planning/DefineWBS'));
const CostBudget = React.lazy(() => import ('../pages/GestioneCommesse/Planning/CostBudget'));
const MilestonePlan = React.lazy(() => import ('../pages/GestioneCommesse/Planning/MilestonePlan'));
const HoursApproval = React.lazy(() => import ('../pages/GestioneCommesse/Execution/ResourceManagement/NewHoursApproval'));
const CurrentSituation = React.lazy(() => import ('../pages/GestioneCommesse/Tracking/CurrentSituation'));
const CostAnalysis = React.lazy(() => import ('../pages/GestioneCommesse/Tracking/CostAnalysis'));
const ProjectEvaluation = React.lazy(() => import ('../pages/GestioneCommesse/Closing/ProjectEvaluation'));
const ClosureSituation = React.lazy(() => import ('../pages/GestioneCommesse/Closing/ClosureSituation'));
const RatesRisorse = React.lazy(() => import('../pages/Rates/RatesRisorse'));
const ErrorPage = React.lazy(() => import('../pages/ErrorPage/ErrorPage'));
const PasswordRecover = React.lazy(() => import('../pages/PasswordRecover/PasswordRecover'));
const ChangeProfile = React.lazy(() => import('../pages/User/ChangeProfile/ChangeProfile'));
const PrenotazioneSala = React.lazy(() => import('../pages/PrenotazioneSala/PrenotazioneSala'));
const GamificationPage = React.lazy(() => import ('../pages/Gamification/GamificationPage'));

// Creazione array contente tutte le informazioni sulle routes da generare
export const routes = [
    // {
    //   pathname: '/',
    //   name: 'Home',
    //   component: Home,
    //   isPrivate: true,
    //   access: [],
    //   users: ['SUPER_USER', 'DIREZIONE', 'DIREZIONE_TECNICA', 'DIREZIONE_COMMERCIALE', 'CONTROLLO_GESTIONE', 'AMMINISTRAZIONE', 'HUMAN_RESOURCES', 'PROJECT_MANAGEMENT', 'RISORSA']
    // },
    {
        pathname: '/login',
        name: 'Login',
        component: Login,
        isPrivate: false,
        access: [],
        users: []
    },
    {
        pathname: '/change-passowrd',
        name: 'Cambio Password',
        component: ChangePassword,
        isPrivate: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE', 'DIREZIONE_TECNICA', 'DIREZIONE_COMMERCIALE', 'CONTROLLO_GESTIONE', 'AMMINISTRAZIONE', 'HUMAN_RESOURCES', 'PROJECT_MANAGEMENT', 'RISORSA']
    },
    {
        pathname: '/offerta',
        name: 'Offerta',
        component: Offerta,
        isPrivate: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_COMMERCIALE', 'AMMINISTRAZIONE', 'DIREZIONE_TECNICA']
    },
    {
        pathname: '/commesse',
        name: 'Commesse',
        component: Commesse,
        isPrivate: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT', 'CONTROLLO_GESTIONE', 'HUMAN_RESOURCES']
    },
    {
        pathname: '/data-summary',
        name: 'Data Summary',
        component: DataExport,
        isPrivate: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'CONTROLLO_GESTIONE', 'PROJECT_MANAGEMENT', 'HUMAN_RESOURCES']
    },
    {
        pathname: '/dettaglio-commessa',
        name: 'Commessa',
        component: LayoutCommessa,
        isPrivate: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT']
    },
    {
        pathname: '/start-commessa',
        name: 'Start Commessa',
        component: Start,
        isPrivate: true,
        wrapped: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT', "HUMAN_RESOURCES"]
    },
    {
        pathname: '/general-data-commessa',
        name: 'Info Generali Commessa',
        component: GeneralData,
        isPrivate: true,
        wrapped: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT']
    },
    {
        pathname: '/project-data',
        name: 'Project Data',
        component: ProjectData,
        isPrivate: true,
        wrapped: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT']
    },
    {
        pathname: '/define-wbs',
        name: 'Define WBS',
        component: DefineWBS,
        isPrivate: true,
        wrapped: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT']
    },
    {
        pathname: '/cost-budget',
        name: 'Cost Budget',
        component: CostBudget,
        isPrivate: true,
        wrapped: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT']
    },
    {
        pathname: '/milestone-plan',
        name: 'Milestone Plan',
        component: MilestonePlan,
        isPrivate: true,
        wrapped: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT']
    },
    {
        pathname: '/hours-approval',
        name: 'Hours Approval',
        component: HoursApproval,
        isPrivate: true,
        wrapped: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT', "HUMAN_RESOURCES"]
    },
    {
        pathname: '/current-situation',
        name: 'Current Situation',
        component: CurrentSituation,
        isPrivate: true,
        wrapped: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT']
    },
    {
        pathname: '/cost-analysis',
        name: 'Cost Analysis',
        component: CostAnalysis,
        isPrivate: true,
        wrapped: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT']
    },

    {
        pathname: '/project-evaluation',
        name: 'Project Evaluation',
        component: ProjectEvaluation,
        isPrivate: true,
        wrapped: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT']
    },
    {
        pathname: '/closure-situation',
        name: 'Closure Situation',
        component: ClosureSituation,
        isPrivate: true,
        wrapped: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_TECNICA', 'PROJECT_MANAGEMENT']
    },
    {
        pathname: '/clienti',
        name: 'Clienti',
        component: Clienti,
        isPrivate: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE_COMMERCIALE', 'DIREZIONE_TECNICA']
    },
    {
        pathname: '/timesheet',
        name: 'Timesheet',
        component: Timesheet,
        isPrivate: true,
        access: [],
        users: ['SUPER_USER', 'RISORSA', 'DIREZIONE_TECNICA']
    },
    {
        pathname: '/anagrafica-risorse',
        name: 'Anagrafica Risorse',
        component: AnagraficaRisorse,
        isPrivate: true,
        access: [],
        users: ['SUPER_USER', 'HUMAN_RESOURCES', 'DIREZIONE_TECNICA']
    },
    {
        pathname: '/costi-mensili',
        name: 'Costi mensili',
        component: RatesRisorse,
        isPrivate: true,
        access: [],
        users: ['DIREZIONE_TECNICA']
    },
    {
        pathname: '/error-page',
        name: 'Error Page',
        component: ErrorPage,
        isPrivate: false,
        access: [],
        users: []
    },
    {
        pathname: '/password-recover',
        name: 'Password Recover',
        component: PasswordRecover,
        isPrivate: false,
        access: [],
        users: []
    },
    {
        pathname: '/change-profile',
        name: 'Change Profile',
        component: ChangeProfile,
        isPrivate: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE', 'DIREZIONE_TECNICA', 'DIREZIONE_COMMERCIALE', 'CONTROLLO_GESTIONE', 'AMMINISTRAZIONE', 'HUMAN_RESOURCES', 'PROJECT_MANAGEMENT', 'RISORSA']
    },
    {
        pathname: '/prenotazione-sala',
        name: 'Prenotazione Sala Riunioni',
        component: PrenotazioneSala,
        isPrivate: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE', 'DIREZIONE_TECNICA', 'DIREZIONE_COMMERCIALE', 'CONTROLLO_GESTIONE', 'AMMINISTRAZIONE', 'HUMAN_RESOURCES', 'PROJECT_MANAGEMENT', 'RISORSA']
    },
    {
        pathname: '/gamification',
        name: 'Gamification',
        component: GamificationPage,
        isPrivate: true,
        access: [],
        users: ['SUPER_USER', 'DIREZIONE', 'DIREZIONE_TECNICA', 'DIREZIONE_COMMERCIALE', 'CONTROLLO_GESTIONE', 'AMMINISTRAZIONE', 'HUMAN_RESOURCES', 'PROJECT_MANAGEMENT', 'RISORSA']
    }
];
