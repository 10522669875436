import { FETCH_PROJECT_TYPES } from '../actions/projectTypes';

const initialState = [];

export const projectTypesReducer = (state=initialState, action) => {
  switch(action.type) {
    case FETCH_PROJECT_TYPES:
      return action.payload
    default:
      return state;
  }
}