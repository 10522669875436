import axiosInstance from "../../api/interceptor";
import {toggleSpinner, toggleToast} from "./general";

import {AUTH_FAIL, AUTH_LOGOUT, AUTH_START, AUTH_SUCCESS} from '../actions/auth';

export const authStart = () => {
    return {
        type: AUTH_START,
        isLoading: true
    };
};

/**
 *
 * @param user
 * @returns {{type: string, error: null, user: *}}
 */
export const authSuccess = (user) => {
    return {
        type: AUTH_SUCCESS,
        user,
        error: null,
        isLoading: false
    };
};

export const authFail = (error) => {
    return {
        type: AUTH_FAIL,
        error,
        isLoading: false
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('user');
    localStorage.removeItem('path');
    return {
        type: AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

// TODO: da cancellare quando ci saranno le chiamate BE
/*
export const tempAuth = user => {
    return dispatch => {
        dispatch(authStart());
        dispatch(toggleSpinner());

        const oreExpiration = 12;
        const expirationDate = new Date(new Date().getTime() + oreExpiration * 60 * 60 * 1000);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('expiration', expirationDate);
        dispatch(toggleSpinner());
        dispatch(authSuccess(user));
    }
};
*/

export const auth = (email, password, isSignup) => {

    return dispatch => {
        dispatch(authStart());
        dispatch(toggleSpinner());

        const authData = {
            username: email,
            password
        };

        axiosInstance.get('/authenticated/me', {auth: authData})
            .then(({data}) => {
                const oreExpiration = 12;
                const expirationDate = new Date(new Date().getTime() + oreExpiration * 60 * 60 * 1000);
                const user = {...data};
                delete user.anagrafica.codiceFiscale;
                delete user.anagrafica.dataNascita;
                delete user.anagrafica.indirizzi;
                delete user.anagrafica.numeriTelefonici;

                const formattedUser = {...user, authoritiesUtente: []}
                const token = btoa(email + ':' + password);
                localStorage.setItem('token', token);
                localStorage.setItem('roleList', JSON.stringify(user.authoritiesUtente));
                localStorage.setItem('expiration', expirationDate);

                if(user.authoritiesUtente.length > 1) {
                    localStorage.setItem('path', '/change-profile');
                    localStorage.setItem('user', JSON.stringify(formattedUser));
                } else {
                    localStorage.setItem('user', JSON.stringify(user));
                }

                //dispatch(toggleSpinner());
                dispatch(authSuccess(user));
            })
            .catch(e => {
                dispatch(authFail(e));
                /**
                 * toggleToast: mostra un toast con un determinato messaggio.
                 * Per veder come funziona guardate la funzione toggleToast prensente in questo file src/store/actionCreators/general.js
                 * @param tipo: @string definisce il tipo di toast da lanciare: 'e' = errore => toast rosso, 's' = successo => toast verde
                 * @param messaggio: @string definisce il messaggio mostrato nel toast
                 */
                dispatch(toggleToast('e', e || 'Se hai già attivato il tuo account controlla le credenziali'));
            })
            .finally(() => { dispatch(toggleSpinner()); });
    };
};

export const authCheckState = () => {
    authStart();

    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expiration'));
            if (expirationDate.getTime() <= new Date().getTime()) {
                dispatch(logout());
            } else {
            const user = localStorage.getItem('user');
            dispatch(authSuccess(JSON.parse(user)));
            dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    };
};
