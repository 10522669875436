import { COMMESSA_SELEZIONATA } from '../actions/commessaSelezionata';
import { UPDATE_COMMESSA } from '../actions/commesse';

//Qui dentro in realtà c'è il wbsID, da rimuovere in futuro
export const commessaSelezionata = (state=null, action) => {
  switch(action.type) {
    case COMMESSA_SELEZIONATA:
      return action.payload;
    case UPDATE_COMMESSA:
      return action.payload;
    default:
      return state;
  }
}
