export const AUTHORITY_TYPES = [ //Questo array deve essere identico all'enum AuthorityType.java

  { description: 'SUPER_USER', value: 0 },

  { description: 'DIREZIONE', value: 1 },
  { description: 'DIREZIONE_TECNICA', value: 1 },
  { description: 'DIREZIONE_COMMERCIALE', value: 1 },

  { description: 'CONTROLLO_GESTIONE', value: 2 },
  { description: 'AMMINISTRAZIONE', value: 2 },
  { description: 'HUMAN_RESOURCES', value: 2 },

  { description: 'PROJECT_MANAGEMENT', value: 3 },

  { description: 'RISORSA', value: 4 }
];

export const AUTHORITIES = {
  superUser: 'SUPER_USER',
  direzione: 'DIREZIONE',
  direzioneTecnica: 'DIREZIONE_TECNICA',
  direzioneCommerciale: 'DIREZIONE_COMMERCIALE',
  controlloGestione: 'CONTROLLO_GESTIONE',
  amministrazione: 'AMMINISTRAZIONE',
  humanResources: 'HUMAN_RESOURCES',
  projectManagement: 'PROJECT_MANAGEMENT',
  risorsa: 'RISORSA'
};

export const isSuperUser = authorities => authorities.includes(AUTHORITIES.superUser);
export const isDirezione = authorities => authorities.includes(AUTHORITIES.direzione);
export const isDirezioneTecnica = authorities => authorities.includes(AUTHORITIES.direzioneTecnica);
export const isDirezioneCommerciale = authorities => authorities.includes(AUTHORITIES.direzioneCommerciale);
export const isControlloGestione = authorities => authorities.includes(AUTHORITIES.controlloGestione);
export const isAmministrazione = authorities => authorities.includes(AUTHORITIES.amministrazione);
export const isHumanResources = authorities => authorities.includes(AUTHORITIES.humanResources);
export const isProjectManagement = authorities => authorities.includes(AUTHORITIES.projectManagement);
export const isRisorsa = authorities => authorities.includes(AUTHORITIES.risorsa);

export const getInitialPathByUserRole = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    const { authoritiesUtente } = JSON.parse(localStorage.getItem('user'));
    return isDirezioneTecnica(authoritiesUtente) ? 'direzione-tecnica' :
      isDirezioneCommerciale(authoritiesUtente) ? 'direzione-commerciale' :
        isControlloGestione(authoritiesUtente) ? 'controllo-gestione' :
          isAmministrazione(authoritiesUtente) ? 'amministrazione' :
            isHumanResources(authoritiesUtente) ? 'human-resources' :
              isProjectManagement(authoritiesUtente) ? 'project-management' :
                isRisorsa(authoritiesUtente) ? 'risorsa' : '';
  } else {
    return 'direzione-tecnica';
  }
};

/**
 * Metodo che verifica se l'utente corrente è autorizzato ad operare/visualizzare una riunione(event).
 * @param {} event 
 * @returns 
 */
export const userIsAuthorized = (event) => {
  if (!event) return true;
  const { id, authUserLevelMax } = JSON.parse(localStorage.getItem('user'));

  let isAuthorized = false;

  //Il numero minore equivale all'autorizzazione più alta.

  //Se l'utente che ha creato la riunione ha la stessa, o una autorizzazione maggiore.
  if (authUserLevelMax < event.authUserLevelMax) isAuthorized = true;

  //Se l'utente che consulta le riunioni è il creatore della riunione stessa.
  if (id === event.idUtente) isAuthorized = true;

  //Se l'utente ha livello di autorizzazione massima.
  if (authUserLevelMax === 0) isAuthorized = true;

  return isAuthorized;
}

export const userRoleIsRisorsa = () => {
  const { authUserLevelMax } = JSON.parse(localStorage.getItem('user'));
  return authUserLevelMax === 4;
}
