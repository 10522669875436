import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { createRoutes } from './routing/utils';
import { auth, authCheckState } from "./store/actionCreators/auth";
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import MiniDrawer from './components/Drawer/Drawer';
import axiosInstance from "./api/interceptor";
import { useHistory } from "react-router-dom";
import { logout } from './store/actionCreators/auth';
import { getBusinessUnitsWbs } from './api/businessUnit/businessUnit';
import { setBusinessUnits, toggleToast } from './store/actionCreators/general';
import { useDispatch } from 'react-redux';


const App = (props) => {
  const { user, tryAutoSignUp, isLoading, logout } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  function checkIsReady() {
    axiosInstance.get('health')
      .then(res => {
        const localUser = localStorage.getItem('user');
        if (!res) {
          if (!localUser) {
            history.push('/error-page')
          } else {
            logout();
            history.push('/error-page');
          }
        } else {
          if (!localUser) {
            history.push('/login')
          }
        }
      })
  }

  function onAppInit() {
    if (user) getBusinessUnitsWbs()
      .then(({data: {content}}) => {
        dispatch(setBusinessUnits(content))
      }).catch(e => dispatch(toggleToast(e)))
  }

  useEffect(() => {
    checkIsReady();
    tryAutoSignUp();
  }, []);//eslint-disable-line

  useEffect(() => {
    onAppInit();
  }, [])//eslint-disable-line

  const routes = useMemo(() => createRoutes(user), [user]);

  return (
    <MiniDrawer>
      <div className="root-container">

        {routes}

        <ToastContainer
          autoClose={5000}
          position="top-right"
          closeOnClick
          pauseOnHover
          hideProgressBar
        />
        {isLoading && <LoadingSpinner />}
      </div>
    </MiniDrawer>

  );
}

const mapStateToProps = ({ auth, general }) => {
  return {
    user: auth.user,
    isLoading: general.loading
  }
}

const mapDispatchToProps = dispatch => ({
  tryAutoSignUp: () => dispatch(authCheckState()),
  onAuth: () => dispatch(auth()),
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
