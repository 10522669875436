import {ADD_RISORSA, REMOVE_RISORSA} from '../actions/anagrafica';

const mockedDipendente = [
    {
        nome: 'Mario',
        cognome: 'Rossi',
        dataNascita: '15/06/1985',
        codiceFiscale: 'ABCDEF88S20Z111C',
        email: 'pec@ped.it',
        tel: ['06/123456', '393/123456'],
        indirizzi: ['Via Ravenna 15, 00100 Roma']
    },
    {
        nome: 'Andrea',
        cognome: 'Forte',
        dataNascita: '07/04/1981',
        codiceFiscale: 'ABCDEF88S20Z111C',
        email: 'forte@forte.it',
        tel: ['06/123456'],
        indirizzi: ['Via Sicilia 10, 00100 Roma', 'Via Barberini 6, 00100 Roma']
    },
    {
        nome: 'Nicola',
        cognome: 'Verdi',
        dataNascita: '01/09/1989',
        codiceFiscale: 'ABCDEF88S20Z111C',
        email: 'venic@gmail.it',
        tel: ['06/123456', '347/123456'],
        indirizzi: ['Via XX Settembre 31, 00100 Roma']
    },
    {
        nome: 'Marco',
        cognome: 'Bianchi',
        dataNascita: '22/012/1986',
        codiceFiscale: 'ABCDEF88S20Z111C',
        email: 'marbi@yahoo.it',
        tel: ['06/123456'],
        indirizzi: ['Via Piave 120, 00100 Roma']
    },
];

export const anagraficaReducer = (state = mockedDipendente, action) => {
    switch (action.type) {
        case ADD_RISORSA:
            return [...state, action.payload];
        case REMOVE_RISORSA:
            const temp = [...state];
            return temp.filter((el, idx) => idx !== action.payload)
        default:
            return state
    }
}
