export const checkDT = (user) => {
  return user?.authoritiesUtente?.filter(authName => authName === 'DIREZIONE_TECNICA').length > 0;
}

export const checkPM = (user) => {
  return user?.authoritiesUtente?.filter(authName => authName === 'PROJECT_MANAGEMENT').length > 0;
}

export const checkDC = (user) => {
  return user?.authoritiesUtente?.filter(authName => authName === 'DIREZIONE_COMMERCIALE').length > 0;
}
export const checkHR = (user) => {
  return user?.authoritiesUtente?.filter(authName => authName === 'HUMAN_RESOURCES').length > 0;
}

export const isDT = checkDT(JSON.parse(localStorage.getItem('user')));
export const isPM = checkPM(JSON.parse(localStorage.getItem('user')));
export const isHR = checkHR(JSON.parse(localStorage.getItem('user')));

export const handleFetchInitialPathByUserRole = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return checkDT(user) ? 'direzione-tecnica' : checkHR(user) ? 'human-resources' : 'project-management'
}
