import {ADD_COMMESSA, FETCH_COMMESSE, UPDATE_COMMESSA} from '../actions/commesse';
import {refactorWord} from "../../components/utils/renderValue";


const commesse = (state = [], action) => {
    switch (action.type) {
        case FETCH_COMMESSE: {

            const {content} = action.payload;
            const contentToLowerCase = content.map(el => (
                {
                    ...el,
                    statoWbs: refactorWord(el.statoWbs?.replaceAll('_', ' ')),
                    projectClass: refactorWord(el.projectClass?.replace('_', ' ')),
                    commessaDto: {
                        ...el.commessaDto,
                        codiceCliente: el.commessaDto?.codiceCliente?.replaceAll('_', ' '),
                        nomeProgetto: refactorWord(el.commessaDto?.nomeProgetto?.replaceAll('_', ' ')),
                        projectType: refactorWord(el.commessaDto?.projectType?.replaceAll('_', ' ')),
                    }
                }
            ))
            return {
                ...action.payload,
                content: contentToLowerCase
            };
        }
        case ADD_COMMESSA:
            return [...state, action.payload];
        case UPDATE_COMMESSA:
            return Object.entries(state).reduce(
                (acc, states) =>
                states[0] === 'content'
                ? {
                    ...acc,
                    [states[0]]: states[1].map((commessa) =>
                        commessa.id === action.payload.id
                        ? action.payload
                        : commessa
                    ),
                }
                : { ...acc, [states[0]]: states[1] },
                {}
            );
        default:
            return state
    }
}

export default commesse;
