import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../store/actionCreators/auth';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PeopleIcon from '@material-ui/icons/People';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ScheduleIcon from '@material-ui/icons/Schedule';
import RateReview from '@material-ui/icons/RateReview';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Avatar from '@material-ui/core/Avatar';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import keyBizBriefLogo from '../../assets/img/kb-logo.png';
import classNames from 'classnames';
import './Drawer.scss';

// STYLING DRAWER
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 10,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function MiniDrawer({ children, logout }) {

    // ----------------------
    // STYLING DRAWER START
    // ----------------------

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const eseguiLogout = () => {
        handleDrawerClose();
        logout()
    }

    // --------------------
    // STYLING DRAWER END
    // --------------------


    // VERIFICA AUTHORITY UTENTE
    const user = JSON.parse(localStorage.getItem('user'));
    const authoritiesUtente = user ? user.authoritiesUtente : [];
    const roleList = JSON.parse(localStorage.getItem('roleList'));

    const verifyAuthoritiesUser = (nomeAuthority) => {
        return authoritiesUtente?.filter(nomeAuth => nomeAuth === nomeAuthority).length > 0;
    }

    // SALVA IL PATH NEL LOCAL STORAGE A OGNI CAMBIO PAGINA
    const history = useHistory();

    const [currentPath, setCurrentPath] = useState('');
    const checkPathname = str => currentPath.includes(str);

    useEffect(() => history.listen(location => {
        if (!location.pathname.includes('login')) {
            localStorage.setItem('path', (location.pathname));
            setCurrentPath(location.pathname)
        }
    }), [history])

    return (
        <div className={classes.root}>
            <CssBaseline />

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >


                <List className="mt-3 mb-3">
                    <ListItem button>
                        <ListItemIcon>
                            <img src={keyBizBriefLogo} onClick={() => history.push('/')} width={4} alt="KeyBiz Logo"
                                id="logo_kb" />
                        </ListItemIcon>
                    </ListItem>
                </List>

                {/* <List className={classNames({ 'd-none': !user })}>
          <ListItem button onClick={() => history.push('/')}>
            <Tooltip title="Home">
              <ListItemIcon><HomeIcon /></ListItemIcon>
            </Tooltip>
            <ListItemText primary={'Home'} />
          </ListItem>
        </List> */}

                <div className="icone_menu">

                    <List className={classNames({
                        'd-none': !(user && (verifyAuthoritiesUser("DIREZIONE_COMMERCIALE") || verifyAuthoritiesUser("DIREZIONE_TECNICA"))),
                        'active': checkPathname('offerta')
                    }, "icone_menu")}>
                        <ListItem button onClick={() => history.push('/offerta')}>
                            <Tooltip title="Offerte">
                                <ListItemIcon><LocalOfferIcon /></ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    </List>

                    <List className={classNames({
                        'd-none': !(user && (verifyAuthoritiesUser("DIREZIONE_COMMERCIALE") || verifyAuthoritiesUser("DIREZIONE_TECNICA"))),
                        'active': checkPathname('clienti')
                    }, "icone_menu")}>
                        <ListItem button onClick={() => history.push('/clienti')}>
                            <Tooltip title="Clienti">
                                <ListItemIcon><PeopleIcon /></ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    </List>

                    <List className={classNames({
                        'd-none': !(user && (
                            verifyAuthoritiesUser("DIREZIONE_TECNICA") ||
                            verifyAuthoritiesUser("DIREZIONE_COMMERCIALE") ||
                            verifyAuthoritiesUser("PROJECT_MANAGEMENT") ||
                            verifyAuthoritiesUser("CONTROLLO_GESTIONE") ||
                            verifyAuthoritiesUser('HUMAN_RESOURCES')
                        )), 'active': checkPathname('commesse')
                    },
                        "icone_menu")}>
                        <ListItem button onClick={() => history.push('/commesse')}>
                            <Tooltip title="Commesse">
                                <ListItemIcon><AssignmentIcon /></ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    </List>

                    <List className={classNames({
                        'd-none': !(user && (verifyAuthoritiesUser("HUMAN_RESOURCES") || verifyAuthoritiesUser("DIREZIONE_TECNICA"))),
                        'active': checkPathname('anagrafica-risorse')
                    }, "icone_menu")}>
                        <ListItem button onClick={() => history.push('/anagrafica-risorse')}>
                            <Tooltip title="Anagrafica">
                                <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    </List>

                    <List className={classNames({
                        'd-none': !(user && (verifyAuthoritiesUser("RISORSA") || verifyAuthoritiesUser("DIREZIONE_TECNICA"))),
                        'active': checkPathname('timesheet')
                    }, "icone_menu")}>
                        <ListItem button onClick={() => history.push('/timesheet')}>
                            <Tooltip title="Timesheet">
                                <ListItemIcon><ScheduleIcon /></ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    </List>

                    <List className={classNames({
                        'd-none': !(user &&
                            (verifyAuthoritiesUser("DIREZIONE_TECNICA") ||
                                verifyAuthoritiesUser("CONTROLLO_GESTIONE") ||
                                verifyAuthoritiesUser("PROJECT_MANAGEMENT") ||
                                verifyAuthoritiesUser("HUMAN_RESOURCES")
                            )), 'active': checkPathname('data-summary')
                    },
                        "icone_menu")}>
                        <ListItem button onClick={() => history.push('/data-summary')}>
                            <Tooltip title="Summary">
                                <ListItemIcon>
                                    <i className="fas fa-file-excel"
                                        style={{
                                            fontSize: '24px',
                                            color: `${checkPathname('data-summary') ? '#0D57A4' : ''}`
                                        }} />
                                </ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    </List>

                    <List className={classNames({
                        'd-none': !(user && (verifyAuthoritiesUser("DIREZIONE_TECNICA")
                        )), 'active': checkPathname('costi-mensili')
                    }, "icone_menu")}>
                        <ListItem button onClick={() => history.push('/costi-mensili')}>
                            <Tooltip title="Costi mensili">
                                <ListItemIcon>
                                    <i
                                        className="fas fa-dollar-sign"
                                        style={{
                                            fontSize: '24px',
                                            color: `${checkPathname('costi-mensili') ? '#0D57A4' : ''}`
                                        }}
                                    />
                                </ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    </List>

                    <List className={classNames({
                        'd-none': !(user && (
                            verifyAuthoritiesUser("SUPER_USER") ||
                            verifyAuthoritiesUser("DIREZIONE") ||
                            verifyAuthoritiesUser("DIREZIONE_TECNICA") ||
                            verifyAuthoritiesUser("DIREZIONE_COMMERCIALE") ||
                            verifyAuthoritiesUser("CONTROLLO_GESTIONE") ||
                            verifyAuthoritiesUser("AMMINISTRAZIONE") ||
                            verifyAuthoritiesUser("HUMAN_RESOURCES") ||
                            verifyAuthoritiesUser("PROJECT_MANAGEMENT") ||
                            verifyAuthoritiesUser("RISORSA")
                        )), 'active': checkPathname('prenotazione-sala')
                    },
                        "icone_menu")}>
                        <ListItem button onClick={() => history.push('/prenotazione-sala')}>
                            <Tooltip title="Prenotazione Sala">
                                <ListItemIcon><RateReview /></ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    </List>
                    <List className={
                        classNames({//eslint-disable-next-line
                            'd-none': !(user && verifyAuthoritiesUser("SUPER_USER") ||
                                verifyAuthoritiesUser("DIREZIONE") ||
                                verifyAuthoritiesUser("DIREZIONE_TECNICA") ||
                                verifyAuthoritiesUser("DIREZIONE_COMMERCIALE") ||
                                verifyAuthoritiesUser("CONTROLLO_GESTIONE") ||
                                verifyAuthoritiesUser("AMMINISTRAZIONE") ||
                                verifyAuthoritiesUser("HUMAN_RESOURCES") ||
                                verifyAuthoritiesUser("PROJECT_MANAGEMENT") || 
                                verifyAuthoritiesUser('RISORSA')),
                            'active': checkPathname('/gamification')
                        }, 'icone_menu')}
                    >
                        <ListItem button onClick={() => history.push('/gamification')}>
                            <Tooltip title="Gamification">
                                <ListItemIcon>
                                    {/* Todo: changeIcon */}
                                    <SportsEsportsIcon />
                                </ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    </List> 

                    {/*<List className={classNames({*/}
                    {/*  'd-none': !(user && (verifyAuthoritiesUser("DIREZIONE_TECNICA") || verifyAuthoritiesUser("PROJECT_MANAGEMENT")*/}
                    {/*  )), 'active': checkPathname('approvazione-massiva')*/}
                    {/*},*/}
                    {/*  "icone_menu")}>*/}
                    {/*  <ListItem button onClick={() => history.push('/approvazione-massiva')}>*/}
                    {/*    <Tooltip title="Approvazione Massiva">*/}
                    {/*      <ListItemIcon>*/}
                    {/*        <i className="fas fa-user-check" */}
                    {/*           style={{ fontSize: '20px', color: `${ checkPathname('approvazione-massiva') ? '#0D57A4' : ''}` }} />*/}
                    {/*      </ListItemIcon>*/}
                    {/*    </Tooltip>*/}
                    {/*  </ListItem>*/}
                    {/*</List>*/}

                    <List className={classNames({ 'd-none': !user }, "icone_menu")}>
                        <ListItem button onClick={eseguiLogout}>
                            <Tooltip title="Logout">
                                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                            </Tooltip>
                        </ListItem>
                    </List>

                </div>

                {!user ? null :
                    <List style={{ position: 'absolute', bottom: '5%' }}>
                        <div className="profile-button">
                            {
                                roleList?.length > 1 &&
                                <div className="profile-menu">
                                    <span onClick={() => history.push('/change-profile')}>Cambio Profilo</span>
                                    <span onClick={() => history.push('/change-passowrd')}>Cambio Password</span>
                                </div>
                            }
                            <ListItem button onClick={() => roleList.length === 1 && history.push('/change-passowrd')}>
                                <Avatar style={{
                                    color: 'white',
                                    backgroundColor: '#255EAD'
                                }}>{user?.anagrafica && `${user.anagrafica.nome[0]}${user.anagrafica.cognome[0]}`}</Avatar>
                            </ListItem>
                        </div>
                    </List>
                }

            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    );
}

export default connect(null, { logout })(MiniDrawer);
