import { FETCH_WBS } from '../actions/allWbs';

const initialState = [];

export const allWbsReducer = (state=initialState, action) => {
  switch(action.type) {
    case FETCH_WBS:
      return action.payload
    default:
      return state;
  }
}