import { combineReducers } from 'redux';
import auth from "./reducers/auth";
import general from './reducers/general';
import { anagraficaReducer } from './reducers/anagrafica';
import { allWbsReducer } from './reducers/allWbs';
import commesse from './reducers/commesse';
import { commessaSelezionata } from './reducers/commessaSelezionata';
import { projectTypesReducer } from './reducers/projectTypes';
import { selectedCommessa } from './reducers/selectedCommessa';

export const rootReducer = combineReducers({
  auth,
  general,
  allWbsReducer,
  anagraficaReducer,
  commesse,
  commessaSelezionata,
  projectTypesReducer,
  selectedCommessa
})
