import axios from 'axios';

const {REACT_APP_BASENAME} = process.env;
const TIMEOUT = 60 * 1000;

const axiosInstance = axios.create({
    timeout: TIMEOUT,
    baseURL: REACT_APP_BASENAME
})

axiosInstance.interceptors.request.use(
    config => {

        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Basic ' + token;
        }

        return config;
    },
    error => {
        Promise.reject(error)
    }
)

const errorHandler = (error) => {
    if (error && error.response) {
        const {status, data: {message}} = error.response;
        let messaggio;
        switch (status) {
            case 401 : {
                messaggio = 'Non sei autorizzato. Controlla le tue credenziali';
                break;
            }
            default:
                messaggio = message ? message : 'Errore generico!'
        }
        return Promise.reject(messaggio)
    }
}

const successHandler = (response) => response;

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)

export default axiosInstance;
