// Vari metodi per formattare date

import moment from "moment";

export const mese = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

// Metodo per trovare il primo giorno della settimana basato su una data
export function startOfWeek(date, singleDay) {
    const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return !singleDay ? new Date(date.setDate(diff)) : moment(date).add(2, 'day').toDate();
}

// Metodo per aggiungere/rimuovere giorni ad una data
export function addDays(dateObj, numDays) {
    const temp = new Date(dateObj)
    temp.setDate(dateObj.getDate() + numDays);
    return temp;
}

// Conversione data a YYYY-MM-DD
export function convertDate(str) {
    const date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

// Conversione data a DD/MM/YYYY
export function convertDateIt(str, symbol) {
    const date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join(!symbol ? "/" : symbol);
}

// Conversione stringa da dd/mm/yyyy a mm/dd/yyyy
export function convertDateString(str) {
    const dateParts = str.split("/");

    // month is 0-based, that's why we need dataParts[1] - 1
    const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

    return new Date(dateObject)
}

// Primo giorno del mese
export function firstDay() {
    const date = new Date(); // data odierna
    // primo giorno del mese
    return new Date(date.getFullYear(), date.getMonth(), 1)
}

// Ultimo giorno del mese
export function lastDay() {
    const date = new Date(); // data odierna
    // ultimo giorno del mese
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

export function getCurrentMonth(day) {
    console.log(day)
}
